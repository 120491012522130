import React from "react"
import { faHome, faDownload, faAddressCard, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom';

export default function Header() {
    return(
        <nav className="header">
            <div className="header--subcategories">
                <div className="link">
                    <FontAwesomeIcon className="icon" icon={faHome}/>
                    <Link to="/" style={ {textDecoration: 'none', color: 'inherit'}} > Home </Link>
                </div>
                <div className="link">
                    <FontAwesomeIcon className="icon" icon={faAddressCard}/>
                    <Link to="/" style={ {textDecoration: 'none', color: 'inherit'}} > About </Link>
                </div>               
                <div className="link">
                    <FontAwesomeIcon className="icon" icon={faEnvelope}/>
                    <Link to="/" style={ {textDecoration: 'none', color: 'inherit'}} > Contact </Link>
                </div>
                <div className="link">
                    <FontAwesomeIcon className="icon" icon={faDownload}/>
                    <Link to="/" style={ {textDecoration: 'none', color: 'inherit'}} > Application </Link>
                </div>
            </div>
            <h1 className="header--title">QTA Property Management</h1>
        </nav>
    )
}
