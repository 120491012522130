// eslint-disable-next-line import/no-anonymous-default-export
export default [
    {
        id: 1,
        title: "2 bedroom 1 bath Duplex",
        description: "",
        price: 859,
        coverImg: "",
        location: "Avon, IN",
    },
    {
        id: 2,
        title: "4 bedroom 2 bath",
        description: "",
        price: 1250,
        coverImg: "",
        stats: {
            rating: 5.0,
            reviewCount: 30
        },
        location: "Plainfield, IN",
    },
    {
        id: 3,
        title: "3 Bedroom 2 Bath",
        description: "",
        price: 1150,
        coverImg: "",
        location: "Danville, IN",
    }
]