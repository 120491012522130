import './App.css';
import React from 'react';
import Header from './components/Header'
import Hero from './components/Hero'
import Body from './components/Body'
import Cards from './components/Cards'
import Footer from './components/Footer'
import Navbar from './components/Navbar';
import data from './homes.js'
import { BrowserRouter as Router } from 'react-router-dom';

function App() {
  const cards = data.map(item => {
    return (
        <Cards
            key={item.id}
            {...item}
            
        />
    )
})
  return (
    <Router >
      <div className="App">
          <Navbar />
          <Header />
          <Hero />
          <Body />
          <section className="cards-list">
                {cards}
            </section>
          <Footer />
        </div>
    </Router>

  );
}

export default App;
